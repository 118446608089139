import { useState, useEffect } from "react";
import axios from 'axios';
import Header from "../Components/Header";
import Button from "../Components/Button";
import { Grid } from "@mui/material";
import Rodape from '../Components/Rodape';
import { useParams, useNavigate } from "react-router-dom";

const Marcas = () => {
    const navigate = useNavigate();
    const {subgrupo} = useParams();
    const [marcas, setMarcas] = useState([]);

    useEffect(() => {
        axios.get(`http://api.sigmasystems.com.br/api/topdistribuidora/marcas?subgrupo=${subgrupo}`)
          .then((response) => {
            setMarcas(response.data);
          })
          .catch((error) => {
            console.error('Erro na requisição:', error);
          });
      }, [subgrupo]);

    const handleMarcaClick = (marca) => {
        const formattedMarca = marca.replace(/ /g, '-'); // Substituir espaços por hífens
        navigate(`/CATEGORIAS/${subgrupo}/${formattedMarca}`);
    };

    return(
        <div>
            <Header/>
            <div className="bodyContainer">
                <Grid columns={{ xs: 4, sm: 8, md: 12 }} container>
                    <Grid item>
                        <Grid container justifyContent="center" spacing={3} width={'100%'} marginLeft={0}>
                            {marcas.map((marca, index) => (
                                // <Button key={index} name={marcas.marcas}/>
                                <Button key={index} name={marca.marca} onClick={() => handleMarcaClick(marca.marca)}/>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Rodape/>
        </div>
    )
}
export default Marcas;