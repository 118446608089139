import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import Header from '../Components/Header';
import Rodape from '../Components/Rodape';
import CardProduto from '../Components/CardProduto';

const Produtos = () => {
  const { subgrupo, marca } = useParams();
  const [produtos, setProdutos] = useState([]);

  useEffect(() => {
    axios.get(`http://api.sigmasystems.com.br/api/topdistribuidora/produtos?subgrupo=${subgrupo}&marca=${marca}`)
      .then((response) => {
        setProdutos(response.data);
      })
      .catch((error) => {
        console.error('Erro na requisição:', error);
      });
  }, [subgrupo, marca]);

  return (
    <div>
      <Header />
      <div className="bodyContainer">
        <Grid columns={{ xs: 4, sm: 8, md: 12 }} container spacing={2} justifyContent='center'>
          <Grid item>
            <Grid container justifyContent="center" spacing={3} width={'100%'} marginLeft={0}>
              {produtos.map((produto, index) => (
    
                <CardProduto key={index} prodNome={produto.nome} prodPreco={produto.preco} foto={produto.foto ? `http://api.sigmasystems.com.br/api/topdistribuidora/uploads/${produto.foto}` : 'http://catalogotopdistribuidora.com.br/images/default.png' }/>
                
              ))}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Rodape />
    </div>
  );
};

export default Produtos;
