function CardProduto(props) {
    return(
        <div className='CardProduto'>
            <div className='CardProdCont'>
                <img className='fotoProd' src={props.foto}></img>
                <div className='CardProdInfos'>
                    <div className='prodNome'>
                        {props.prodNome}
                    </div>
                    <div className='prodPreco'>
                        R$ {props.prodPreco}
                    </div>  
                </div>
            </div>
        </div>
    )
}
export default CardProduto;