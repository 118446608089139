import {Route, BrowserRouter, Routes,} from "react-router-dom";
import Home from "../Home";
import Categoria from "../Categoria";
import Marcas from "../Marcas";
import Produtos from "../Produtos";
import Login from "../Login";
import Admin from "../Admin";


const Rotas = () => {
    return(
        
        <BrowserRouter>
            <Routes>
                <Route element = { <Home/> } path="/" exact/>
                <Route element = { <Home/> } path="/home"/>
                <Route element = { <Categoria/> } path="/CATEGORIAS"/>
                <Route element = { <Marcas/> } path="/CATEGORIAS/:subgrupo"/>
                <Route element = { <Produtos/> } path="/CATEGORIAS/:subgrupo/:marca"/>
                <Route element = { <Login/> } path="/admin"/>
                <Route element = { <Admin/> } path="/dashboard"/>
            </Routes>
        </BrowserRouter>
        
    )
}



export default Rotas;