import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useNavigate } from 'react-router-dom';
import Rodape from '../Components/Rodape';

const Home = () => {
    const navigate = useNavigate();
    const goCategoria = () => {
        navigate('/CATEGORIAS')
    }
    
    return (
        <div className="container">
            
            <div className="content">
                <div className="leftSide">
                    <div className="wellcome-box">
                        <h2 className="wellcome">Bem Vindo</h2>
                        <h2 className="wellcome-desc">Acesse nosso catálogo de produtos.</h2>
                    </div>
                    
                    <div className="contact">
                        <h2 className="contact-us">Entre em contato conosco!</h2>
                        <a href='https://wa.me/+554599224018'><button className="whatsapp"><WhatsAppIcon fontSize='small' sx={{paddingRight:0.5}}/>Contato</button></a>
                    </div>
                    
                </div>
                <div className="rightSide">
                    <img className="logoTopHome" src="./images/toplogo.png"></img>
                    <button className='btnCatalogo' onClick={goCategoria}>ACESSAR CATÁLOGO</button>
                </div>
                
            </div>
            <Rodape/>
            
        </div>
    )
}
export default Home;