import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
function Rodape() {
    return(
        <div className="rodape">
            <div className="rodape-content">
                <img className="logoRodape" src='/images/toplogo2.png'></img>    
                <div className="rodapeInfo">
                    <div style={{marginBottom:20}}>
                        <a className="rodapeLink">Política de Privacidade</a><a>|</a><a className="rodapeLink">Termos de Uso</a>
                    </div>
                    <div>
                        <a className="rodapeDir">2024</a><a>|</a><a className="rodapeDir">Todos os direitos reservados.</a>
                    </div>
                </div>
                <div className='rodapeSocial'>
                    <FacebookOutlinedIcon sx={{color:'#FFF', marginInline:2}}/>
                    <InstagramIcon sx={{color:'#fff', marginInline:2}}/>
                </div>
            </div>
        </div>
    )
}
export default Rodape;