import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import {useNavigate, useLocation} from 'react-router-dom';

function Header () {
    const location = useLocation();
    const navigate = useNavigate();
    const navHome = () => {
        navigate('/categorias')
    }

    const pathnames = location.pathname.split('/').filter((x) => x);

    const handleNavigation = (index) => {
        const pathTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        navigate(pathTo);
    };

    return(
        <div>
           <div className="bg">
                <div className="headContainer">
                    <div className="headContent">
                        <div className="headTitle">
                            <a>CATÁLOGO DE PRODUTOS</a>
                        </div>
                        <div className="headCaminho">
                            {pathnames.map((value, index) => {
                                const isLast = index === pathnames.length - 1;
                                const decodedValue = decodeURIComponent(value); // Decodificar a string
                                return (
                                <div key={index} className='caminho' onClick={() => handleNavigation(index)}>
                                    <ChevronRightRoundedIcon sx={{ color: '#CA0202' }} />
                                    {isLast ? (
                                    <span>{decodedValue.toUpperCase()}</span>
                                    ) : (
                                    <a>{decodedValue.toUpperCase()}</a>
                                    )}
                                </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
           </div>
        </div>
    )
}
export default Header;