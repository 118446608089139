import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { TextField, Button } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


function Admin() {
  const [message, setMessage] = useState('');
  const [produtos, setProdutos] = useState([]);
  const [selectedRow, setSelectedRow] = useState({ foto: '', nome: '', codigo: '' });
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openEx, setOpenEx] = useState(false);
  const [error, setError] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(false);
    setOpen(false);
    setOpenEx(false);
  };

  const handleRowClick = (params) => {
    setSelectedRow(params.row);
  };
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/admin');
    }

    axios.get('http://api.sigmasystems.com.br/api/topdistribuidora/admin/produtos', {
      headers: { Authorization: token }
    })
      .then((response) => {
        if (Array.isArray(response.data)) {
          setProdutos(response.data);
        } else {
          console.error('Erro: A resposta da API não é um array', response.data);
          setMessage('Erro ao buscar produtos');
        }
      })
      .catch((error) => {
        setMessage('Erro ao acessar área de administração');
        navigate('/admin');
      });
  }, [navigate]);

  const columns = [
    { field: 'codigo', headerName: 'Código', width: 80 },
    {
      field: 'nome',
      headerName: 'Título',
      width: 550
    },
    {
      field: 'subgrupo',
      headerName: 'Categoria',
      width: 180
    },
    {
      field: 'marca',
      headerName: 'Marca',
      width: 150
    },
    {
      field: 'foto',
      headerName: 'Foto',
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <img src={params.value ? `http://api.sigmasystems.com.br/api/topdistribuidora/uploads/${params.value}` : 'https://catalogotopdistribuidora.com.br/images/default.png'}  style={{ width: '50px', height: '50px' }} />
      )
    },
  ];

  

  const handleUpload = () => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('image', image);
   

    if (selectedRow.codigo+'-'+image.name !== selectedRow.foto) {

      axios.post(`http://api.sigmasystems.com.br/api/topdistribuidora/upload/${selectedRow.codigo}`, formData, {
        headers: {
          Authorization: token,
          'Content-Type': 'multipart/form-data'
        }})
        .then((response) => {
          setProdutos((prevProdutos) =>
            prevProdutos.map((produto) =>
              produto.codigo === selectedRow.codigo ? { ...produto, foto: response.data.filename } : produto
            )
          );
          setSelectedRow((prevSelectedRow) => ({
            ...prevSelectedRow,
            foto: response.data.filename
          }));
          setOpen(true);
        })
        .catch((error) => {
          console.error('Erro ao fazer upload da imagem:', error);
      });
    }
    if (selectedRow.codigo+'-'+image.name === selectedRow.foto) {
      setError(true);
      
    }
    
  };
  const handleExclude = (productId) => {
    const token = localStorage.getItem('token');
    axios.post(`http://api.sigmasystems.com.br/api/topdistribuidora/excluir-foto`, {productId} ,{
      headers: {
        Authorization: `Bearer ${token}`,  // Adiciona o token corretamente ao header
        'Content-Type': 'application/json',
      }})
      .then((response) => {
        console.log('Foto excluída com sucesso:', response.data);
        setOpenEx(true);
        setProdutos((prevProdutos) =>
          prevProdutos.map((produto) =>
            produto.codigo === productId ? { ...produto, foto: null } : produto
          )
        );
        setSelectedRow((prevSelectedRow) => ({
          ...prevSelectedRow,
          foto: null // Define como null para exibir a imagem padrão
        }));
      })
      .catch((error) => {
        console.error('Erro ao excluir a foto:', error);
      });
  }

  return (
    <div className='bg-login'>
      <div className="adminContainer">
        <div className="adminContent">
          <div className="LeftSideAdmin">
            <div className="adminHeader">
              <h2>Lista de Produtos</h2>
            </div>
            <Box sx={{marginTop:2, height:620, width: '100%' }}>
              <DataGrid
                rows={produtos}
                columns={columns}
                getRowId={(row) => row.codigo}
                pageSizeOptions={[10]}
                onRowClick={handleRowClick}
                sx={{backgroundColor:'#fff'}}
              />
              </Box>
          </div>
          <div className="line"></div>
          <div className='rightSideAdmin'>
            <h2>Informações do Produto</h2>
            {selectedRow && (
              <div className='prodFotoColumn'>
                <div>{selectedRow.nome}</div>
                <img className='fotoBanco' src={selectedRow.foto ? `http://api.sigmasystems.com.br/api/topdistribuidora/uploads/${selectedRow.foto}` : 'http://catalogotopdistribuidora.com.br/images/default.png'} alt={selectedRow.nome}/>                
                <label className="btnUpload">
                  <input type="file" accept="image/*" onChange={handleImageChange} />
                  Trocar Foto
                </label>
                <Button onClick={handleUpload}>Salvar</Button>
                <Button style={{backgroundColor:'#b60000', color:'#fff', marginTop:170}} onClick={() => handleExclude(selectedRow.codigo)}>Excluir Foto</Button>
            </div>
            )}
          </div>
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Foto alterada com Sucesso!
        </Alert>
      </Snackbar>
      <Snackbar open={openEx} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Foto Excluída com Sucesso!
        </Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Erro! Imagens com mesmo nome!
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Admin;
